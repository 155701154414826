body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,
  'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


@font-face {
    font-family: houseFont;
    src: url("museo-slab-cufonfonts/Museo_Slab_100.otf") format("opentype");
}

:root {
  --content-width: 900px;
  --menu-width: 200px;
  --menu-border: 2px;
  /* --app-width: 1000px; */
  --app-width: calc(var(--content-width) + var(--menu-width));
  --menu-button-border: 5px;
  --accent-text: lightblue;
  --font: 'houseFont',sans-serif;
  --link-color: gray;
  --link-color-highlighted: white;
  --link-weight: 660;
  --link-hightlight-duration: 0.5s;
}

/* * {
  font-family: 'Museo Slab', sans-serif;
} */
  
@keyframes toContentBackgroundAnimation {
  from {
    background-position: top;
    height: 100%;
  }
  to {
    height: 800%;
    background-position:right;
  }
}

@keyframes toHomeBackgroundAnimation {
  from {
    height: 800%;
    background-position:right;
  }
  to {
    height: 100%;
  }
}

/* .homePageBackground { */
  /* height: 100%; */
  /* background-position-y: -100px; */
  /* animation-name: toHomeBackgroundAnimation;
  animation-duration: 2s; */
/* } */

.contentPageBackground {
  /* height: 900%; */
  /* transform: scale(9.0); */
  background-image: url("../public/media/crop2.jpg");
  background-position-y: 0px;
  background-position: right;
  /* animation-name: toContentBackgroundAnimation;
  animation-duration: 2s; */
}

.pageHeader {
  width: 100%;
  /* justify-content: center; */
  /* display: inline-block; */
  /* margin: 2rem; */
}

/* .mobile-page-header { */
  /* display: inline-block; */
  /* float: center; */
  /* float: left; */
/* } */

.hamburger-react {
  /* display: inline-block; */
  display: table-cell;
  float: right;
  margin-right: 2rem;
}


.headerContent {
  display: table-cell;
  vertical-align: middle;
  /* display: inline; */
  color:var(--accent-text);
  padding-left: 1rem;
  /* margin-bottom: -1.5rem; */
  font-size:1.8rem;
  font-weight: 600;
}
/* h1 {
} */

.headerSubcontent {
  display: inline-block;
  color:lightcyan;
}
/* h2 {
  color:lightcyan;
} */

.background {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -100;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  /* background-size: */
  background-repeat: no-repeat;
  overflow: hidden;
  background-color: black;
}

.homePageBackground {
  background-image: url("../public/media/sitting.jpg");
  /* height: 100%; */
  /* width: 100%; */
  /* background-position-y: -100px; */
  /* background-position-y: 100px; */
  /* animation-name: toHomeBackgroundAnimation;
  animation-duration: 2s; */
  /* animation-name: backgroundAnimation; */
  /* animation-duration: 10s; */
}


.App {
  display: flex;
  /* position: fixed; */
  /* height: 800%; */
  width: 100%;
  /* height: 100%; */
  font-family: var(--font);
}

.app-content {
  /* font-family: Arial,Helvetica,sans-serif; */
  font-size: 1.3rem;
  display: flex;
  /* position: fixed; */
  /* height: 100%; */
  width: 100%;
  /* max-width: var(--app-width); */
  /* background-color: #111; */
  /* background-color:#222; */
  /* background-color: */
  /* background-image: linear-gradient(to bottom, rgba(1, 1, 1, 0.2), rgba(1, 1, 1, 1.0)), url("../public/media/sitting.jpg"); */
  
  justify-content: center;
}

a {
  font-weight: var(--link-weight);
}

a:link {
  color: var(--link-color);
  transition: color var(--link-hightlight-duration) ease;
}

a:visited {
  color: antiquewhite;
}

a:hover {
  color: var(--link-color-highlighted);
  transition: color var(--link-hightlight-duration) ease;
}

a:active {
  color:aquamarine
}

.youtube {
  width: 100%;
  aspect-ratio: 16 / 9;
}

#jeffsFace {
  width: 40%;
  max-width: 400px;
  border-radius: 10px;
  margin-right:1rem;
  float: left;
}

#email {
  color:var(--accent-text);
  font-weight:700;
}


.header {
  display: flex;
  margin-bottom: 1rem;
}

.header img {
  width: var(--menu-width);
  height: auto;
  cursor: pointer;
  border-radius: var(--menu-button-border);
}

.mobile-navigation-menu {
  display: table;
  /* vertical-align: middle; */
  padding:1rem;
  position: fixed;
  background-color: rgb(25, 29, 28);
  /* height: 200px; */
  width: 100%;
  top: 0px;
  left: 0px;
}

.navigation-menu {
  width: var(--menu-width);
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem;
  margin-top: 50px;
  /* background-color: #111; */
  /* border-right-style: solid; */
  /* border-right-width: var(--menu-border); */
  /* border-right-color: #222; */
}

.main-section {
  width: 100%;
  max-width: var(--content-width);
  padding: 1rem 2rem;
  /* padding-left: 2rem;
  padding-right: 2rem; */
  font-size: 1.2rem;
  font-weight: 400;
  color: #BBB;
  overflow-y: auto;
  overflow-x: auto;
}

@keyframes toFocusedButton {
  from {
    color:rgb(135, 182, 178);
  }
  to {
    color: white;
  }
}

@keyframes toUnfocusedButton {
  from {
    color: white;
  }
  to {
    color:rgb(135, 182, 178);
  }
}

.navigationButton {
  /* display: block; */
  margin-top: 0.3rem;
  /* width: var(--menu-width); */
  border-color: transparent;
  background-color: transparent;
  font-family: var(--font);
  font-size: 1.3rem;
  font-weight: var(--link-weight);
  text-align: left;
  /* color:rgb(135, 182, 178); */
  cursor: pointer;
  transition: color var(--link-hightlight-duration) ease; 
}

button.desktop {
  display: block;
  width: var(--menu-width);
  color:rgb(135, 182, 178);
}

button.mobile {
  display: inline;
  color:rgb(203, 215, 214);
}

.navigationButton:hover {
  color: white;
  transition: color var(--link-hightlight-duration) ease;
  /* text-decoration: underline; */
}


.stream {
  padding-inline: 2rem;
  padding-block: 2rem;
}

.event {
  text-align: left;
  margin-bottom: 1rem;
}

.eventInfo {
  font-size: 0.9rem;
}

.date {
  color: var(--accent-text);
  font-weight: var(--link-weight);
  font-size: 1.1rem;
}

.friendList {
  text-align: left;
  list-style-type: none;
}

.accentText {
  color: var(--accent-text);
}

.bioParagraph {
  margin-bottom: 1rem;
}



/* discog */
.highlighted {
  color:rgb(212, 145, 96);
}

.discogTitle {
  font-size: x-large;
  margin-bottom: 20px;
  /* text-align: center; */
}

.discogImage {
  height: 300px;
  width: 300px;
}

.discogContainer {
  display: inline-block;
  vertical-align: top;
  /* margin-bottom: 20px; */
}

.trackList {
  /* vertical-align: center; */
  margin-top: 20px;
  margin-left: 20px;
  width: 45%;
}

.coverArt {
  margin-left: 10px;
  /* float: right; */
}

.movement {
  margin-left: 30px;
}


/* press photo */
.smallPressPhoto {
  /* height: 400px; */
  width: calc(var(--content-width)/3 - 50px);
  margin: 5px;
}

.smallPressPhotoContainer {
  display: flex;
  flex-flow: row wrap;
}


/* contact */
:root {
  --label-width: 4rem;
  --label-margin: 1rem;
  --input-width: 15rem;
  --input-bgcolor: rgb(194, 194, 194);
  /* --contactButtonColor: rgb(0, 0, 0); */
}

.inputLabel {
  display: inline-block;
  width: var(--label-width);
  text-align: right;
  margin-right: var(--label-margin);
  margin-bottom: 0.5rem;
}

/* input { */
  /* margin: 1rem; */
  /* display: inline-block; */
  /* width: var(--input-width); */
  /* font-family: Arial, Helvetica, sans-serif; */
  /* background-color: var(--input-bgcolor); */
/* } */

textarea {
  padding-top: 5px;
  width: calc(var(--label-width) + var(--input-width) + var(--label-margin));
  height: 100px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: var(--input-bgcolor);
}
  
.contactForm {
  /* text-align: center; */
  margin-top: 2rem;
  padding: 1rem 5%;
  background-color: rgba(218, 255, 245, 0.25);
  border-radius: 7px;
  
}

form {
  justify-content: center;
  text-align: center;
  /* display: inline-block; */
}

.contactButton {
  /* color:var(--link-color); */
  transition: color var(--link-hightlight-duration) ease;

  border-color: transparent;
  background-color: transparent;
  text-align: center;
  font-family: var(--font);
  font-size: 1.3rem;
  font-weight: var(--link-weight);
}

.contactButton:hover {
  color: var(--link-color-highlighted);
  transition: color var(--link-hightlight-duration) ease;
}